/* GameSelectionWithPoints.module.css */
.gameList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gameListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px; 
  padding: 3px 0;
}

.pointsInput {
  width: 50px;
}

.column {
  flex-basis: 30%;
  max-width: 30%;
  padding: 0 10px;
  box-sizing: border-box;
}
